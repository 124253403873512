@import "scss/_utilities.scss";

.loading {
    align-items: center;
    display: flex;
    justify-content: center;
    .spinner {
        display: inline-block;
        width: 8rem;
        height: 8rem;
    }
    .spinner:after {
        content: " ";
        display: block;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        border: 6px solid #000;
        border-color: $primary-color transparent;
        animation: spinner 5s linear infinite;
    }
    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(720deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
}
