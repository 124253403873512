@import "./scss/config";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    align-items: center;
    background-color: $dark-gray-color;
    display: flex;
    font-family: $font-family-body;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
    #root {
        width: inherit;
    }
}

code {
    font-family: $font-family-code;
}
