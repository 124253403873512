// Fonts
$font-family-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-code: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;

// Colors
$dark-gray-color: #2f353a;
$medium-gray-color: #343b41;
$light-gray-color: #3a4149;
$extra-light-gray-color: #515b65;

$email-input-gray-color: #e1e1e3;
$border-gray-color: #a9aaaf;

$disable-text-color: #f4f4f4af;

$primary-color: #1277c3;

$light-color: #f4f4f4;
$active-link-color: #2883a5;

$hover-menu-color: #2883a5;

$inactive-status-color: #f86c6b;
$active-status-color: #5dc380;

$danger-color: #b25859;

$max-width: 1100px;

// Set text color based on background
@function set-text-color($color) {
    @if (lightness($color) > 60) {
        @return #000;
    } @else {
        @return #fff;
    }
}

@mixin set-background($color) {
    background-color: $color;
    color: set-text-color($color);
}

/**
 * Adding font-smoothing for browsers that do support it
 */
@mixin font-smoothing() {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}

// Margin & Padding
$spaceamounts: (5, 10, 15, 20, 25, 30);

@each $space in $spaceamounts {
    // All around margin
    .m-#{$space} {
        margin: #{$space}px;
    }

    // Horizontal Margin
    .mx-#{$space} {
        margin: 0 #{$space}px;
    }

    // Vertical Margin
    .my-#{$space} {
        margin: #{$space}px 0;
    }

    // All around Padding
    .p-#{$space} {
        padding: #{$space}px;
    }

    // Horizontal Padding
    .px-#{$space} {
        padding: 0 #{$space}px;
    }

    // Vertical Padding
    .py-#{$space} {
        padding: #{$space}px 0;
    }
}

.m-auto {
    margin: auto;
}
