@import "scss/_utilities.scss";

.search {
    background-color: $border-gray-color;
    border: 0.6rem solid $border-gray-color;
    border-radius: 3rem;
    display: flex;
    justify-content: space-between;
    min-width: 35rem;
    &-bar {
        border-radius: 4rem;
        border: none;
        width: 80%;
    }
    button {
        border-radius: 1rem;
    }
    input {
        @include set-background($email-input-gray-color);
        outline: none;
        text-align: center;
    }
}
