@import "config";

// Text
.large {
    font-size: 3rem;
    line-height: 1.2;
}

.headline {
    font-size: 2rem;
    line-height: 1.2;
}

.regular {
    font-size: 1.5rem;
}

.lead {
    font-size: 1rem;
}

.error {
    color: red;
    font-size: 0.8rem;
}


.text-primary {
    color:#fff;
}

// Backgrounds
.bg {
    &-dark {
        @include set-background($dark-gray-color);
    }
    &-medium {
        @include set-background($medium-gray-color);
    }
    &-light {
        @include set-background($light-gray-color);
        // border: #ccc 1px solid;
    }
    &-extra-light {
        @include set-background($extra-light-gray-color);
        // border: #ccc 1px solid;
    }
    &-danger {
        @include set-background($danger-color);
    }
    &-white {
        @include set-background(#fff);
        border: #ccc 1px solid;
    }
}

// Buttons
.btn {
    background-color: extra-light-gray-color;
    color: #333;
    border: none;
    cursor: pointer;
    display: inline;
    font-size: 1rm;
    outline: none;
    padding: 0.4rem 1.2rem;
    transition: all 0.2s ease-in;

    &.btn-disabled {
        opacity: 0.8;
        pointer-events: none;
    }
    
    &.btn-primary {
        @include set-background($primary-color);
        &:hover {
            background: lighten($primary-color, 5%);
        }
    }

    &.btn-dark {
        @include set-background($dark-gray-color);
        &:hover {
            background: lighten($dark-gray-color, 5%);
        }
    }

    &.btn-medium {
        @include set-background($medium-gray-color);
        &:hover {
            background: lighten($medium-gray-color, 5%);
        }
    }

    &.btn-light {
        @include set-background($light-gray-color);
        &:hover {
            background: lighten($light-gray-color, 5%);
        }
    }

    &.btn-extra-light {
        @include set-background($extra-light-gray-color);
        &:hover {
            background: lighten($extra-light-gray-color, 5%);
        }
    }
    &.btn-danger {
        @include set-background($danger-color);
        &:hover {
            background: lighten($danger-color, 5%);
        }
    }
    &.btn-lg {
        align-items: center;
        border-radius: 1.5rem;
        display: flex;
        font-size: 1.3rem;  
        justify-content: center;
        padding: 1rem 1.4rem;
        width: 17rem;
        img {
            margin-right: .2rem;
            height: 1.5rem;
            width: 1.5rem
        }
    }
    &:hover {
        background: darken(#fff, 30%);
        color: #fff;
    }
}

.container {
    border-radius: .5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);    
    overflow: hidden;
}

.header {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.body {
    color: #fff;
    padding: 0.5rem 1.2rem;
}

.footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1.2rem;
}

// Close buttton
#close {
    background-color: Transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    outline: none;
    overflow: hidden;
}

// Remove after creating layout
.top-left {
    position: absolute;
    left: 0;
    top: 0;
}