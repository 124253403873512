@import "scss/_utilities.scss";

.user-info {
    color: $disable-text-color;
    display: flex;
    flex-direction: column;
    width: 30%;  
    input:disabled {
        @include set-background($extra-light-gray-color);
        color: $disable-text-color;
        border: .1rem solid $dark-gray-color;
        border-radius: .3rem;
        padding: .4rem .6rem;
    }
}