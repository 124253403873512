@import "scss/_utilities.scss";
@import "scss/_config.scss";

//HOW THIS WORKS:
//The 0fr column takes up no space (yet).
//The two 1fr columns are the toggle nub(??), and the ~visible~ empty space.
//When checked, the left column becomes 1fr and the right column becomes 0fr, creating the effect of the nub moving.

//====================

//Change width or padding values freely :)
//But for the 'nub' to look perfectly circular (at 1fr, i.e. 50%), the toggle's height must be half its width, plus the padding value
$width: 3rem;
$padding: 0.1rem;
$height: $width / 2 + $padding;

.switch {
    align-items: center;
    display: flex;
    //toggle code begins!
    &-wrap {
        background: #fff;
        border-radius: $height / 2;
        height: $height;
        padding: $padding;
        width: $width;
        input {
            cursor: pointer;
            height: inherit;
            opacity: 0;
            position: absolute;
            width: inherit;
        }
    }

    .toggle {
        height: 100%;
        display: grid;
        grid-template-columns: 0fr 1fr 1fr;

        //ICYMI, pseudo elements are treated as grid items
        &::after {
            content: "";
            border-radius: 50%;
            background-color: #ccc;
            grid-column: 2;
            transition: background-color 0.5s;
        }
    }
    input:checked {
        + .toggle {
            grid-template-columns: 1fr 1fr 0fr;
            &::after {
                background-color: $primary-color;
            }
        }
    }
}
